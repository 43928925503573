import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import { APPLICATION_SEARCH_API } from "../config/keys"

// Images
import SearchIcon from "../images/icon/search-icon.svg"
import BlueLabel from "../images/logo-blue.png"
import SilverLabel from "../images/logo-silver.png"
import GoldLabel from "../images/logo-gold.png"
import GradientImage from "../images/gradient-big.png"

const Lieferantendatenbank = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueryText, setSearchQueryText] = useState("")
  const [listOfCompamies, setListOfCompanies] = useState([])
  const [errorMessage, setErrorMessage] = useState({
    state: false,
    message: "",
  })

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/supplier-database")
    }
  }, [])

  function updateSearchQuery(event) {
    setSearchQuery(event.target.value)
  }

  function handleEnter(event) {
    if (event.key !== "Enter" && event.key !== "NumpadEnter") {
      return
    }
    searchForCompanies(event)
  }

  async function searchForCompanies(event) {
    if (event !== undefined) event.preventDefault()
    if (searchQuery.length < 3) {
      setErrorMessage({
        state: true,
        message: "Bitte geben sie zumindest drei zusammenhängende Zeichen ein",
      })
      return
    } else {
      setErrorMessage({ state: false, message: "" })

      const response = await fetch(`${APPLICATION_SEARCH_API}?q=${searchQuery}`)
      const responseData = await response.json()

      const companiesList = responseData.data
      setSearchQueryText(searchQuery)
      setListOfCompanies(companiesList)
    }
  }

  function getLogo(type) {
    switch (type) {
      case 0:
        return BlueLabel
      case 1:
        return GoldLabel
      case 2:
        return SilverLabel
      default:
        return BlueLabel
    }
  }

  return (
    <React.Fragment>
      <PageTitle
        title={"Lieferanten und Organisationen mit Cyber Trust Austria Label"}
      />
      <Layout>
        <div className="request-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Lieferantendatenbank</a>
              </li>
            </ul>
          </div>
          <div className="request">
            <h1>
              Lieferanten und Organisationen mit Cyber Trust Austria
              <span className="sup" style={{ color: "white" }}>
                ®
              </span>{" "}
              Label
            </h1>

            <div className="request__wrapper">
              <div className="request__wrapper--info">
                <p>
                  Das Cyber Trust Austria Label gibt Organisationen die
                  Möglichkeit, nach außen sichtbar zu zeigen, dass sie
                  essenzielle Mindestsicherheitsmaßnahmen für Cybersicherheit
                  umgesetzt haben und das Thema einen entsprechenden Stellenwert
                  in der Organisation hat.
                </p>
              </div>

              <h2>
                Suchen Sie nach Lieferanten und Organisationen mit gültigem
                Cyber Trust Austria<span className="sup">®</span> Label hier:
              </h2>
              <div className="request__search">
                <form className="form__request" onSubmit={searchForCompanies}>
                  <div className="form__request--group">
                    <div className="icon">
                      <img
                        src={SearchIcon}
                        alt="search"
                        title="search"
                        className="mw-100"
                      ></img>
                    </div>
                    <input
                      type="search"
                      placeholder="Geben Sie den Namen des Unternehmens ein"
                      value={searchQuery}
                      onChange={updateSearchQuery}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <div className="form__request--button">
                    <button type="submit" className="page__btn page__btn--blue">
                      Suchen
                    </button>
                  </div>
                </form>
              </div>

              <div className="request__block">
                <div className="page__flex">
                  <p
                    className="popup__error-indicator"
                    style={{ display: `${errorMessage.state ? "" : "none"}` }}
                  >
                    {errorMessage.message}
                  </p>
                  <div className="request__block--box">
                    <div className="title">
                      {searchQueryText === "" ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          Aktuell gültige Labels von Organisationen mit dem
                          Suchbegriff «{searchQueryText}»{" "}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="request__block--box"></div>
                </div>
              </div>

              <div className="request__description">
                {listOfCompamies.length > 0 && (
                  <div className="request__table">
                    <div className="request__table--header">
                      <div className="request__table--header--cell">
                        Name des Unternehmens
                      </div>
                      <div className="request__table--header--cell">FN/VN</div>
                      <div className="request__table--header--cell">Label</div>
                    </div>
                    {listOfCompamies.map((company, index) => {
                      return (
                        <div className="request__table--row" key={index}>
                          <div className="request__table__cell_title">
                            Name des Unternehmens <br></br>
                          </div>
                          <div className="request__table--row--cell">
                            {company.org_name}
                          </div>
                          <div className="request__table__cell_title">
                            FN/VN <br></br>
                          </div>
                          <div className="request__table--row--cell">
                            {company.registered_number}
                          </div>
                          <div className="request__table__cell_title">
                            Label <br></br>
                          </div>
                          <div className="request__table--row--cell">
                            <div className="image">
                              <img
                                className="mw-100"
                                src={getLogo(company.gold_label)}
                                alt={company.org_name}
                                title={company.org_name}
                                style={{
                                  filter: company.is_outdated
                                    ? "grayscale(100%)"
                                    : "unset",
                                  opacity: company.is_outdated ? 0.25 : "unset",
                                  webkitFilter: company.is_outdated
                                    ? "grayscale(100%)"
                                    : "unset",
                                }}
                              />
                              {company.is_outdated ? (
                                <div className="expired">EXPIRED</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Lieferantendatenbank
